import React from "react"

import Layout from '../../components/layout'
import GamesTabbed from '../../components/games_tabbed'
import Head from '../../components/head'

const Games = () => {


    return (
        <Layout>
            <Head
                title="nfelo Model Performance"
                pageDescription="Performance of the nfelo prediction model"
            />
            <GamesTabbed
                activeIndex={3}
            />
        </Layout>
    )
}

export default Games

